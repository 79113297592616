import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import LandingPageType from "./landing-page-type"
import Copy from "./copy"
import Title from "../../components/hero/title.js"
import Subtitle from "../../components/hero/subtitle.js"
import HubspotForm from "../../components/hubspot-form"
import Container from "../../components/container"
import HeroContainer from "../../components/containers/hero"
import PullBelowHeader from "../../components/containers/pull-below-header"
import SkewedBackground from "../../components/decoration/skewed-background"

import { radii, mediaQueries, colors, space } from "../../utils/presets"

const TwoColumnDarkHeaderLandingPage = ({ contentfulLandingPage }) => {
  const {
    landingPageType,
    title,
    subtitle,
    illustration,
    primaryCopy,
    secondaryCopy,
    formHeader: { formHeader },
    form,
  } = contentfulLandingPage

  return (
    <Fragment>
      <PullBelowHeader>
        <HeroContainer
          isInverted
          customStyles={{
            backgroundColor: colors.night,
            position: `relative`,
          }}
        >
          <Container>
            <div
              css={{
                width: `100%`,
                [mediaQueries.tablet]: {
                  width: `70%`,
                  paddingRight: space[9],
                },
              }}
            >
              {landingPageType && (
                <LandingPageType isInverted>{landingPageType}</LandingPageType>
              )}
              <Title isInverted>{title}</Title>
              {subtitle && <Subtitle isInverted>{subtitle.subtitle}</Subtitle>}
            </div>
          </Container>
          <div css={{ paddingTop: illustration ? `160px` : `100px` }}>
            <SkewedBackground shape="wave" />
          </div>
        </HeroContainer>
      </PullBelowHeader>

      <Container
        customStyles={{
          marginTop: illustration ? `-160px` : space[6],
          zIndex: 10,
        }}
      >
        <div
          css={{
            display: `flex`,
            flexDirection: `column`,
            [mediaQueries.tablet]: { flexDirection: `row` },
          }}
        >
          <div
            css={{
              width: `100%`,
              maxWidth: `100%`,
              [mediaQueries.desktop]: {
                maxWidth: `70%`,
                paddingRight: space[9],
              },
            }}
          >
            {illustration && (
              <Img
                fluid={illustration.fluid}
                alt={illustration.title}
                css={{ marginBottom: space[6] }}
              />
            )}
            {primaryCopy && (
              <Copy html={primaryCopy.childMarkdownRemark.html} />
            )}
            {secondaryCopy && (
              <Copy html={secondaryCopy.childMarkdownRemark.html} />
            )}
          </div>
          <div css={{ paddingLeft: space[9] }}>
            <div
              css={{
                backgroundColor: colors.white,
                borderRadius: radii[2],
                border: `1px solid ${colors.gray.light}`,
                padding: space[8],
                position: `relative`,
                top: 0,
                [mediaQueries.tablet]: {
                  top: `-200` ? `-200` : `-250`,
                },
              }}
            >
              <h3 css={{ color: colors.gatsby }}>{formHeader}</h3>
              <HubspotForm
                {...form}
                singleColumn
                wrapperCss={{
                  "& .hs-form": {
                    padding: 0,
                    marginTop: space[3],
                    marginBottom: 0,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </Fragment>
  )
}

TwoColumnDarkHeaderLandingPage.propTypes = {
  contentfulLandingPage: PropTypes.object.isRequired,
}

export default TwoColumnDarkHeaderLandingPage
