import React, { Component } from "react"
import PropTypes from "prop-types"
import HubspotForm from "react-hubspot-form"
import {
  inputStyles,
  textAreaStyles,
  labelStyles,
  formStyles,
  errorLabelStyles,
  selectStyles,
} from "./form"
import { buttonStyles } from "../components/core/button"
import { colors, fontSizes, space } from "../utils/presets"

class GatsbyHubspotForm extends Component {
  static propTypes = {
    singleColumn: PropTypes.bool,
    wrapperCss: PropTypes.object,
  }
  render() {
    const { singleColumn } = this.props

    return (
      <div
        css={{
          ".hs-form": {
            ...formStyles,
            marginTop: 24,
            marginBottom: 24,
            "& fieldset": {
              maxWidth: `none`,
              width: `100%`,
            },
            "& label": labelStyles,
            "& .hs-error-msgs label": errorLabelStyles,
            "& .hs-form-field": {
              marginBottom: 20,
              border: `none`,
              zIndex: 10,
              display: `block`,
            },
            ".hs-form-required": {
              color: colors.gray.bright,
            },
            '& input[type="text"], & input[type="email"], & input[type="tel"], & textarea, & select': {
              ...inputStyles,
              position: `relative`,
            },
            "& input.error": {
              borderColor: colors.warning,
              ":focus": {
                borderColor: colors.gatsby,
              },
            },
            "& select": {
              ...selectStyles,
              width: `100% !important`,
            },
            '& input[type="radio"]': {
              width: `auto`,
              marginRight: 8,
            },
            "& ul.inputs-list": {
              listStyleType: `none`,
              margin: 0,
            },
            "& .hs-button.primary": {
              ...buttonStyles.primary[`&&`],
              alignItems: `center`,
              cursor: `pointer`,
              display: `flex`,
              justifyContent: `center`,
              padding: `${space[2]} ${space[4]}`,
              fontSize: `${fontSizes[2]} !important`,
            },
            "& textarea": {
              ...textAreaStyles,
              position: `relative`,
              width: `100% !important`,
            },
          },
          ".form-columns-1 .input, .form-columns-2 .input": {
            marginRight: `${space[4]} !important`,
          },
          "& .field": {
            width: singleColumn ? `100% !important` : `inherit`,
          },
          "& label.hs-form-radio-display, & label.hs-form-checkbox-display": {
            fontSize: fontSizes[2],
            color: colors.gray.copy,
            display: `inline-flex`,
            alignItems: `baseline`,
            margin: 0,
            "& .hs-input": {
              marginRight: space[2],
              flexShrink: 0,
            },
          },
          ...this.props.wrapperCss,
        }}
      >
        <HubspotForm
          {...this.props}
          loading={<div>Loading...</div>}
          {...{ css: `` }}
        />
      </div>
    )
  }
}

export default GatsbyHubspotForm
