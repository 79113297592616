import React from "react"
import PropTypes from "prop-types"
import deepmerge from "deepmerge"

import {
  radii,
  transition,
  fontSizes,
  colors,
  space,
} from "../../utils/presets"

export const labelStyles = {
  boxSizing: `border-box`,
  color: colors.gray.calm,
  display: `block`,
  fontSize: fontSizes[1],
}

export const invertedLabelStyles = {
  boxSizing: `border-box`,
  color: colors.ui.light,
  display: `block`,
  width: `100%`,
}

export const errorLabelStyles = {
  color: colors.warning,
}

export const Label = ({
  children,
  customStyles,
  inverted,
  labelFor,
  required,
  error,
}) => (
  <label
    css={deepmerge.all([
      inverted ? invertedLabelStyles : labelStyles,
      error ? errorLabelStyles : {},
      customStyles ? customStyles : {},
    ])}
    htmlFor={labelFor}
  >
    {children}
    {required ? ` *` : ``}
  </label>
)

Label.propTypes = {
  children: PropTypes.node.isRequired,
  customStyles: PropTypes.object,
  error: PropTypes.bool,
  inverted: PropTypes.bool,
  labelFor: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

export const selectStyles = {
  WebkitAppearance: `none`,
  background: `${
    colors.white
  } url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right .75rem center/8px 10px`,
}

export const inputStyles = {
  boxSizing: `border-box`,
  backgroundColor: colors.white,
  border: `1px solid ${colors.gray.light}`,
  borderRadius: radii[1],
  caretColor: colors.gatsby,
  color: colors.gray.copy,
  padding: 8,
  width: `100%`,
  outline: `none`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  "::placeholder": {
    color: colors.lilac,
    opacity: 1,
  },
  ":focus": {
    borderColor: colors.gatsby,
    outline: 0,
    boxShadow: `0 0 0 0.2rem ${colors.ui.bright}`,
  },
}

export const invertedInputStyles = {
  backgroundColor: `transparent`,
  border: `none`,
  borderBottom: `3px solid ${colors.ui.light}`,
  boxSizing: `border-box`,
  caretColor: colors.ui.light,
  caretWidth: `2px`,
  color: colors.ui.light,
  padding: 8,
  width: `100%`,
  outline: `none`,
  transition: `border-color ${transition.speed.default} ${
    transition.curve.default
  }`,
  "&:focus": {
    borderColor: colors.purple[`400`],
  },
}

export const Input = ({
  autoComplete,
  customStyles,
  id,
  inverted,
  name,
  onChange,
  type = `text`,
  value,
}) => (
  <input
    css={deepmerge(
      inverted ? invertedInputStyles : inputStyles,
      customStyles ? customStyles : {}
    )}
    autoComplete={autoComplete}
    type={type}
    value={value}
    onChange={onChange}
    name={name}
    id={id || name}
  />
)

Input.propTypes = {
  autoComplete: PropTypes.string,
  customStyles: PropTypes.object,
  id: PropTypes.string,
  inverted: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
}

export const textAreaStyles = {
  boxSizing: `border-box`,
  backgroundColor: colors.white,
  border: `1px solid ${colors.gray.light}`,
  borderRadius: radii[1],
  padding: 8,
  width: `100%`,
  outline: `none`,
  transition: `all ${transition.speed.default} ${transition.curve.default}`,
  "::placeholder": {
    color: colors.lilac,
    opacity: 1,
  },
  ":focus": {
    borderColor: colors.lilac,
    outline: 0,
    boxShadow: `0 0 0 0.2rem ${colors.ui.bright}`,
  },
}

export const invertedTextAreaStyles = {
  backgroundColor: `transparent`,
  border: `none`,
  borderBottom: `3px solid ${colors.ui.light}`,
  boxSizing: `border-box`,
  caretColor: colors.ui.light,
  caretWidth: `2px`,
  color: colors.ui.light,
  padding: 8,
  resize: `none`,
  width: `100%`,
}

export const TextArea = ({
  customStyles,
  id,
  inverted,
  name,
  onChange,
  value,
}) => (
  <textarea
    css={deepmerge(
      inverted ? invertedTextAreaStyles : textAreaStyles,
      customStyles ? customStyles : {}
    )}
    onChange={onChange}
    name={name}
    id={id || name}
    rows={5}
    value={value}
  />
)

TextArea.propTypes = {
  customStyles: PropTypes.object,
  id: PropTypes.string,
  inverted: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

export const radioButtonStyles = {
  display: `flex`,
  justifyContent: `flex-start`,
  alignItems: `center`,
}

export const radioLabelStyles = {
  color: colors.gatsby,
  paddingLeft: space[2],
}

export const invertedRadioLabelStyles = {
  color: colors.ui.light,
  paddingLeft: space[2],
}

export const RadioButton = ({
  customStyles,
  error,
  id,
  label,
  name,
  onChange,
  value,
  inverted,
  required,
}) => (
  <div
    css={{
      ...radioButtonStyles,
      ...customStyles,
    }}
  >
    <input
      type="radio"
      name={name}
      id={id}
      checked={value}
      value={name}
      onChange={onChange}
    />
    <label
      css={{
        ...(inverted ? invertedRadioLabelStyles : radioLabelStyles),
        ...(error ? errorLabelStyles : {}),
        ...customStyles,
      }}
      htmlFor={id}
    >
      {label}
      {required ? ` *` : ``}
    </label>
  </div>
)

RadioButton.propTypes = {
  customStyles: PropTypes.object,
  error: PropTypes.bool,
  id: PropTypes.string.isRequired,
  inverted: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.bool,
}

export const checkBoxStyles = {
  display: `flex`,
  justifyContent: `flex-start`,
  alignItems: `center`,
}

export const checkBoxLabelStyles = {
  color: colors.gatsby,
  paddingLeft: space[2],
}

export const invertedCheckBoxStyles = {
  color: colors.ui.light,
  paddingLeft: space[2],
}

export const CheckBox = ({
  customStyles,
  error,
  id,
  label,
  name,
  onChange,
  value,
  inverted,
  required,
}) => (
  <div
    css={{
      ...checkBoxStyles,
      ...customStyles,
    }}
  >
    <input
      type="checkbox"
      name={name}
      id={id || name}
      value={name}
      checked={value}
      onChange={onChange}
    />
    <label
      css={{
        ...(inverted ? invertedCheckBoxStyles : checkBoxLabelStyles),
        ...(error ? errorLabelStyles : {}),
        ...customStyles,
      }}
      htmlFor={id || name}
    >
      {label}
      {required ? ` *` : ``}
    </label>
  </div>
)

CheckBox.propTypes = {
  customStyles: PropTypes.object,
  error: PropTypes.bool,
  id: PropTypes.string,
  inverted: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  required: PropTypes.bool,
  value: PropTypes.bool,
}

export const formStyles = {
  boxSizing: `border-box`,
  padding: space[7],
}

export const Form = ({ children, onSubmit, customStyles }) => (
  <form
    css={{
      ...formStyles,
      ...customStyles,
    }}
    onSubmit={onSubmit}
  >
    {children}
  </form>
)

Form.propTypes = {
  children: PropTypes.node.isRequired,
  customStyles: PropTypes.object,
  onSubmit: PropTypes.func,
}
