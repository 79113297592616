import React, { Fragment } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import LandingPageType from "./landing-page-type"
import Copy from "./copy"
import Title from "../../components/hero/title.js"
import Subtitle from "../../components/hero/subtitle.js"
import HubspotForm from "../../components/hubspot-form"

import { radii, colors, space } from "../../utils/presets"
import { rhythm } from "../../utils/typography"
import { guideContainer } from "../../utils/styles"

const CenterColumn = ({ children }) => (
  <div css={{ ...guideContainer, position: `relative` }}>{children}</div>
)

const Illustration = ({ fullWidth, illustration }) => {
  if (fullWidth) {
    return (
      <Img
        fluid={illustration.fluid}
        alt={illustration.title}
        css={{ marginBottom: space[6] }}
      />
    )
  } else {
    return (
      <CenterColumn>
        <Img
          fluid={illustration.fluid}
          alt={illustration.title}
          css={{ marginBottom: space[6] }}
        />
      </CenterColumn>
    )
  }
}

const OneColumnLandingPage = ({ contentfulLandingPage }) => {
  const {
    landingPageType,
    title,
    subtitle,
    illustration,
    primaryCopy,
    secondaryCopy,
    formHeader: { formHeader },
    form,
  } = contentfulLandingPage

  return (
    <Fragment>
      <CenterColumn>
        <div css={{ textAlign: `center`, marginTop: `8vw` }}>
          {landingPageType && (
            <LandingPageType>{landingPageType}</LandingPageType>
          )}
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle.subtitle}</Subtitle>}
        </div>
      </CenterColumn>

      {illustration && (
        <Illustration
          fullWidth={
            contentfulLandingPage.layout === `One Column Full Width Image`
          }
          illustration={illustration}
        />
      )}

      <CenterColumn>
        {primaryCopy && <Copy html={primaryCopy.childMarkdownRemark.html} />}
      </CenterColumn>

      <div
        css={{
          maxWidth: rhythm(36),
          margin: `0 auto`,
          marginBottom: space[6],
          padding: space[13],
          border: `1px solid #eee`,
          borderRadius: radii[1],
          backgroundColor: colors.white,
        }}
      >
        <CenterColumn>
          <h2>{formHeader}</h2>
          <HubspotForm
            {...form}
            wrapperCss={{
              "& .hs-form": {
                padding: 0,
              },
            }}
          />
        </CenterColumn>
      </div>
      <CenterColumn>
        {secondaryCopy && (
          <Copy html={secondaryCopy.childMarkdownRemark.html} />
        )}
      </CenterColumn>
    </Fragment>
  )
}

OneColumnLandingPage.propTypes = {
  contentfulLandingPage: PropTypes.object.isRequired,
}

export default OneColumnLandingPage
