import React from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"

import LandingPageType from "./landing-page-type"
import Copy from "./copy"
import Title from "../../components/hero/title.js"
import Subtitle from "../../components/hero/subtitle.js"
import HubspotForm from "../../components/hubspot-form"
import Container from "../../components/container"
import Metaball from "../../components/metaball"

import { colors, mediaQueries, space } from "../../utils/presets"

const breakpoint = mediaQueries.desktop

const TwoColumnLandingPage = ({ contentfulLandingPage }) => {
  const {
    landingPageType,
    title,
    subtitle,
    illustration,
    primaryCopy,
    secondaryCopy,
    formHeader: { formHeader },
    form,
  } = contentfulLandingPage

  return (
    <Container customStyles={{ marginTop: space[9] }}>
      <div
        css={{
          display: `flex`,
          flexDirection: `column`,
          [breakpoint]: {
            flexDirection: `row`,
          },
        }}
      >
        <Metaball fill={colors.magenta[`50`]} right />
        <div
          css={{
            width: `100%`,
            [breakpoint]: {
              width: `60%`,
              marginRight: space[10],
            },
          }}
        >
          {landingPageType && (
            <LandingPageType>{landingPageType}</LandingPageType>
          )}
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle.subtitle}</Subtitle>}
          {primaryCopy && <Copy html={primaryCopy.childMarkdownRemark.html} />}
          {secondaryCopy && (
            <Copy html={secondaryCopy.childMarkdownRemark.html} />
          )}
        </div>
        <div css={{ [breakpoint]: { marginLeft: space[4] } }}>
          {illustration && (
            <Img
              fluid={illustration.fluid}
              alt={illustration.title}
              css={{ marginBottom: space[6] }}
            />
          )}
          <div css={{ display: `flex` }}>
            <div css={{ width: `100%` }}>
              <h3
                css={{
                  color: `#663399`,
                }}
              >
                {formHeader}
              </h3>
              <HubspotForm
                {...form}
                singleColumn
                wrapperCss={{
                  "& .hs-form": {
                    padding: 0,
                    marginTop: space[3],
                    marginBottom: 0,
                  },
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

TwoColumnLandingPage.propTypes = {
  contentfulLandingPage: PropTypes.object.isRequired,
}

export default TwoColumnLandingPage
