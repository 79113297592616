import React from "react"

import { space, colors } from "../../utils/presets"
import { smallCapsStyles } from "../../utils/styles"

const LandingPageType = ({ children, isInverted }) => (
  <div
    css={{
      ...smallCapsStyles,
      color: isInverted ? colors.purple[`300`] : false,
      marginBottom: space[4],
    }}
  >
    {children}
  </div>
)

export default LandingPageType
